.externalApplicationText {
  text-align: center;
  color: #0468b1;
  font-size: 24px;
  padding: 0 10px;
}

@media (max-width: 768px) {
  .externalApplicationText {
    font-size: 18px;
  }
}

.jobDetailsHeader {
  display: flex;
  flex-direction: row;
}

@media (max-width: 768px) {
  .jobDetailsHeader {
    flex-direction: column;
  }
}

.jobDetailsOptions {
  min-width: max-content;
}
