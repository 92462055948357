.institution-logo {
  min-height: 52px;
  max-height: 52px;
  max-width: none!important;
}

.filter-popover {
  min-width: 300px;
}

.profile-section-wrapper .panel-block.cv {
  margin: 20px 0 40px !important;
}

.profile-section-wrapper .file-title .text-dark-blue {
  color: #7A8E92;
}

.profile-section-wrapper .files-under-item-light {
  transform: rotate(-5deg);
  top: 30px;
}

.profile-section-wrapper .files-under-item-dark {
  transform: rotate(-10deg);
  top: 25px;
}

.middle-name .form-control, .last-name .form-control {
  margin-top: 21px;
}

/* Large desktops and laptops */
@media only screen and  (min-width: 1200px) {

}

/* Landscape tablets and medium desktops */
@media only screen and  (min-width: 992px) and (max-width: 1199px) {
  .files-list__file-label.files-list__file-label_inline .badge {
    position: absolute;
    float: none!important;
    bottom: 10px;
  }

  .files-list__file-label.files-list__file-label_inline .badge + .files-list__file-left-part {
    margin-bottom: 30px;
  }

  .files-list__file-left-part * {
    vertical-align: top;
  }

  .file-title {
    max-width: 90%;
  }

}

/* Portrait tablets and small desktops */
@media only screen and  (min-width: 769px) and (max-width: 991px) {

  .files-list__file-label.files-list__file-label_inline .badge {
    position: absolute;
    float: none!important;
    bottom: 10px;
  }

  .files-list__file-label.files-list__file-label_inline .badge + .files-list__file-left-part {
    margin-bottom: 30px;
  }

  .files-list__file-left-part * {
    vertical-align: top;
  }

  .file-title {
    max-width: 90%;
  }

}

/* Landscape phones and portrait tablets */
@media only screen and  (min-width: 576px) and (max-width: 768px) {
  .files-list__file-label.files-list__file-label_inline .badge {
    position: absolute;
    float: none!important;
    bottom: 10px;
  }

  .files-list__file-label.files-list__file-label_inline .badge + .files-list__file-left-part {
    margin-bottom: 30px;
  }

  .files-list__file-left-part * {
    vertical-align: top;
  }

  .file-title {
    max-width: 90%;
  }

}

/* Small phones */
@media (max-width: 575px) {
  .files-list__file-label.files-list__file-label_inline .badge {
    position: absolute;
    float: none!important;
    bottom: 10px;
  }

  .files-list__file-label.files-list__file-label_inline .badge + .files-list__file-left-part {
    margin-bottom: 30px;
  }

  .files-list__file-left-part * {
    vertical-align: top;
  }

  .file-title {
    max-width: 90%;
  }

}
