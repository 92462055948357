.message-writer-container {
  display: flex;
}

.messenger-text {
  width: 100%;
  resize: none;
  border-radius: 4px;
  outline: none;
  padding: 2px 10px;
}

.messenger-text[disabled] {
  opacity: 0.6;
}

.write .input-group > .input-group-btn:first-child .btn, .write .input-group > .input-group-btn:first-child {
  min-width: 51px;
}

.write.candidate .input-group > .input-group-btn:first-child .btn, .write.candidate .input-group > .input-group-btn:first-child {
  min-width: 1px;
}

.write .input-group > .input-group-btn:first-child .btn-group {
  display: table-row;
}

.messenger-text + .input-group-btn {
  width: 39px;
}

.btn-send, .write .input-group > .input-group-btn:first-child .btn-group .btn {
  position: absolute !important;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%
}

.write.candidate .input-group > .input-group-btn:first-child + .messenger-text {
  border-radius: 4px;
}

.write .input-group > .input-group-btn:first-child + .messenger-text {
  border-radius: 0;
}

.btn-send .ll-icon {
  font-size: 22px;
  color: #1088E2;
}

.btn-send.btn-sm {
  padding: 2px 10px;
}

#wrapper-note {
  border-left: 1px dotted #DEE7EB;
}

#webchat i, #facebook i, #note i, .lnr-phone-handset {
  font-size: 16px;
}

#sms i, .chat-options .lnr-paperclip {
  font-size: 17px;
}

.chat-options {
  margin-top: 5px;
}

.chat-options > div {
  display: inline-block;
  vertical-align: middle;
  padding: 2px 7px;
}

.chat-options .btn-sm {
  line-height: 1.4em;
  padding: 0;
  width: 19px;
  height: 19px;
}

#wrapper-sms {
  padding-right: 14px;
}

#wrapper-note {
  padding-left: 14px;
}

.attachment {
  font-size: 20px;
  display: inline-block;
  vertical-align: middle;
  color: #96ACBF;
}

.btn-attachment {
  position: relative;
  padding-left: 0;
  padding-right: 10px;
}

/*** sticky date ***/
.sticky-date {
  background: rgba(32, 75, 111, 0.8);
  color: #fff;
  font-size: 12px;
  position: absolute;
  z-index: 1000;
  display: block;
  left: calc(50% - 80px);
  top: 5px;
  padding: 5px 15px;
  border-radius: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .175);
  min-width: 160px;
}

.wide-two-cols .sticky-date {
  left: calc(25% - 80px);
}

.candidate .sticky-date {
  top: 55px;
}

.typing {
  position: relative;
  font-size: 13px;
}

.typing .fa-circle {
  display: inline-block;
  font-size: 4px;
  margin-right: 4px;
  opacity: 0.8;
  animation: wave 1.1s linear infinite;
}

.typing .fa-circle:nth-child(2) {
  animation-delay: -0.9s;
}

.typing .fa-circle:nth-child(3) {
  animation-delay: -0.7s;
  margin-right: 0;
}

@keyframes wave {
  0%, 60%, 100% {
    transform: initial;
  }
  30% {
    transform: translateY(-4px);
  }
}

@-moz-document url-prefix() {
  .write .input-group > .input-group-btn:first-child .btn-group {
    position: absolute;
    height: 100%;
  }
  .write .input-group > .input-group-btn:first-child .btn, .write .input-group > .input-group-btn:first-child {
    vertical-align: top;
  }
}

