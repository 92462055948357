.chatcontainer {
  display: flex;
  flex-direction: row;
  flex: 1;
  background: #fff;
}

.messenger-container, .center-right-content {
  display: flex;
  flex: 1 1 0;
  flex-direction: row;
}

.chatcontainer .left:not(.carousel-control):not(.item) {
  height: calc(100vh - 51px);
  height: -moz-calc(100vh - 51px);
  height: -webkit-calc(100vh - 51px);
  width: calc(25vw - 90px);
  width: -moz-calc(25vw - 90px);
  width: -webkit-calc(25vw - 90px);
  border-right: 1px solid #DEE7EB;
  background: #f2f6f9;
  position: relative;
}

.chatcontainer .left .top {
  position: relative;
  padding: 10px;
  z-index: 10;
  border-bottom: 1px solid #DEE7EB;
}

.chatcontainer .left-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 23px;
  background: #fff;
}

.chatcontainer .left-bottom .logo {
  height: 42px;
}

.chatcontainer.cats .right .chat {
  padding: 50px 10px 20px;
}

.chatcontainer.cats .right:not(.carousel-control):not(.item) {
  height: calc(100vh - 51px);
  overflow: auto;
}

.chatcontainer .right:not(.carousel-control):not(.item), .message-container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  z-index: 5;
  width: calc(100vw - 507px);
}

.message-container {
  max-width: calc(35vw - 1px);
  position: relative;
}

.right > .tablet-mode-on.show-pane {
  display: none;
}

.right > .tablet-mode-on.hide-pane {
  display: inherit;
}

.chatcontainer .right .top {
  width: 100%;
  height: 100px;
  padding: 20px 18px;
  background-color: #29638F;
  position: absolute;
  z-index: 3;
}

.chatcontainer .right .panel-body .top {
  height: 100px;
  padding: 20px 18px;
  background-color: #29638F;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.chatcontainer .right .panel-collapse {
  position: relative;
}

.chatcontainer .right .chat {
  width: 100%;
  position: relative;
  display: none;
  overflow: hidden;
  padding: 50px 10px 20px;
  min-height: 100%;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.chatcontainer .right .chat.active-chat {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.chatcontainer .right .chat.active-chat small {
  font-size: 11px;
}

.chatcontainer .right .chat.active-chat > div {
  margin-bottom: 15px;
}

.chatcontainer .right .chat.active-chat > div + div[draggable="true"], .chatcontainer .right .chat.active-chat > div[draggable="true"] + div {
  margin-top: 5px;
}

.chatcontainer .right .write {
  border-top: 1px solid #DEE7EB;
  padding: 10px 0;
  min-height: 67px;
  margin: 0 10px;
}

.chatcontainer .right .write .input-group {
  width: 100%;
}

.cv-block .chatcontainer .right .chat.active-chat {
  padding: 50px 8% 20px;
}

.cards-candidate {
  margin-bottom: 30px;
}

/*** bof bubble ***/
.bubble {
  position: relative;
  display: inline-block;
  clear: both;
  padding: 13px 15px;
  vertical-align: top;
  border-radius: 5px;
  min-width: 138px;
  width: auto;
  max-width: 90%;
}

.bubble .Select, .bubble select.form-control {
  margin-top: 10px;
}

.bubble.you {
  float: left;
  background: #f2f6f9;
}

.bubble.you.unread {
  background: #eef3f6;
}

.bubble.you:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 7px 12px 10px 0;
  border-color: transparent #f2f6f9;
  bottom: 15px;
  left: -10px;
  height: 17px;
}

.bubble.you.unread:after {
  border-color: transparent #eef3f6;
}

.bubble.you:before {
  background-color: #fff;
  border-radius: 50%;
  bottom: 23px;
  content: "";
  display: block;
  height: 15px;
  left: -15px;
  position: absolute;
  width: 15px;
  z-index: 10;
}

.bubble.me {
  float: right;
  color: #fff;
  background-color: #29638F;
}

.bubble.me.unread {
  background-color: #204B6F;
}

.bubble.me:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 7px 0 10px 12px;
  border-color: transparent #29638F;
  bottom: 15px;
  right: -10px;
  height: 17px;
}

.bubble.me.unread:after {
  border-color: transparent #204B6F;
}

.bubble.me:before {
  background-color: #fff;
  border-radius: 50%;
  bottom: 23px;
  content: "";
  display: block;
  height: 15px;
  right: -15px;
  position: absolute;
  width: 15px;
  z-index: 1;
}

.bubble.me .bubble.me {
  float: none;
  margin-right: 20px;
  min-width: 70px;
  background: #204b6f;
  margin-top: 10px;
}

.bubble.me .bubble.me:before {
  background-color: #29638F;
}

.bubble.me .bubble.me:after {
  border-color: transparent #204b6f;
}

.bubble-content .participant-name {
  color: #29638F;
  font-size: 13px;
  display: inline-block;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 700;
}

.bubble .bubble-content a {
  text-decoration: underline;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.bubble .bubble-content a.details {
  text-decoration: none;
}

.bubble.me .bubble-content a, .bubble.me .bubble-content .participant-name {
  color: rgb(213, 224, 234);
}

.bubble.you .bubble-content .bubble.me:before {
  background-color: #f2f6f9;
}

.bubble-content .bubble.me {
  margin-top: 10px;
}

.bubble.me .attach-file {
  color: #fff;
}

.bubble .dropdown-menu {
  color: #7a8e92;
}

.bubble-wrapper {
  position: relative;
  display: inline-block;
  clear: both;
  vertical-align: top;
  min-width: 138px;
  width: auto;
  max-width: 85%;
}

.bubble-wrapper.me {
  float: right;
  padding-right: 10px;
  /*padding-right:40px; - place for avatar*/
}

.bubble-wrapper.you {
  float: left;
  padding-left: 10px;
  /*padding-left:40px; - place for avatar*/
}

.bubble-wrapper .bubble {
  max-width: 100%;
  width: 100%;
  margin-top: 5px;
  padding: 10px 15px;
}

.bubble-wrapper .bubble:before, .bubble-wrapper .bubble:after {
  display: none;
}

.bubble-wrapper .bubble:last-child:before, .bubble-wrapper .bubble:last-child:after {
  display: block;
}

.bubble-wrapper .participant-name {
  color: #29638F;
  font-size: 12px;
  display: inline-block;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 500;
}

.bubble-wrapper .chatsend-time {
  color: #96ACBF;
  padding-left: 10px;
  font-size: 11px;
  float: right;
  margin-top: 1px;
}

/*** BOF for future ***/
.msg-avatar {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 6px;
  z-index: 11;
}

.bubble-wrapper.you .msg-avatar {
  left: 0;
}

.bubble-wrapper.me .msg-avatar {
  right: 0;
}

.msg-avatar .user-avatar-crop, .msg-avatar .user-avatar {
  width: 100%;
}

.msg-avatar .user-avatar {
  border-width: 1px;
  margin: 0;
}

/*** EOF for future ***/

.bubble-wrapper .message-header {
  margin: 0 0 -5px 0;
  padding: 0 5px;
}

.bubble-content {
  min-height: 12px;
}

.bubble .media-body, .bubble .media-left, .bubble .media-right {
  vertical-align: middle;
  overflow: visible;
}

.bubble .media-right {
  white-space: nowrap;
}

.facebook-msg .bubble.me {
  background-color: #0084ff;
  color: #fff;
  border-radius: 13px;
}

.facebook-msg .bubble.me::after {
  border-color: transparent #0084ff;
  display: inline-block;
}

.facebook-msg .bubble.you {
  background-color: #f1f0f0;
  color: rgba(0, 0, 0, 1);
  border-radius: 13px;
}

.facebook-msg .bubble.you::after {
  border-color: transparent #f1f0f0;
  display: inline-block;
}

.sms-msg .bubble.me {
  background-color: rgb(76, 217, 100);
  color: #fff;
  border-radius: 8px;
}

.sms-msg .bubble.me::after {
  border-color: transparent rgb(76, 217, 100);
  display: inline-block;
}

.sms-msg .bubble.you {
  background-color: #E5E4E9;
  color: #363636;
  border-radius: 8px;
}

.sms-msg .bubble.you::after {
  border-color: transparent #E5E4E9;
  display: inline-block;
}

.sms-msg .bubble.me::before, .sms-msg .bubble.you::before,
.facebook-msg .bubble.me::before, .facebook-msg .bubble.you::before {
  display: inline-block;
}

/*** eof bubble ***/

.chatcontainer .right .conversation-start, .tov-wrapper .conversation-start {
  position: relative;
  margin: 10px 0;
  text-align: center;
  font-size: 12px;
}

.chatcontainer .right .conversation-start span:before, .chatcontainer .right .conversation-start span:after,
.tov-wrapper .conversation-start span:before, .tov-wrapper .conversation-start span:after {
  position: absolute;
  top: 10px;
  display: inline-block;
  width: 30%;
  height: 1px;
  content: '';
  background-color: #DEE7EB;
}

.chatcontainer .right .conversation-start span:before,
.tov-wrapper .conversation-start span:before {
  left: 0;
}

.chatcontainer .right .conversation-start span:after,
.tov-wrapper .conversation-start span:after {
  right: 0;
}

.center-right-content {
  display: flex;
  width: 100%;
}

.chatcontainer .message-container + .cats {
  height: calc(100vh - 51px);
  height: -moz-calc(100vh - 51px);
  height: -webkit-calc(100vh - 51px);
  width: 40vw;
  order: 2;
  background: #f2f6f9;
  border-left: 1px solid #DEE7EB;
  position: relative;
}

.chatcontainer .right .top .top-left {
  float: left;
}

.chatcontainer .right .top .top-right {
  float: right;
  color: #efefef;
}

.top-left .chatbody-hld {
  display: flex;
}

.chatcontainer .left .top .fa {
  font-size: 21px;
  color: #fff;
  cursor: pointer;
}

.chatcontainer .left .top button {
  width: 50%;
}

.conv-wrapper {
  overflow: auto;
  background-color: #fff;
}

.conv-wrapper.agent {
  height: -moz-calc(100vh - 118px);
  height: -webkit-calc(100vh - 118px);
  height: calc(100vh - 118px);
}

.chatcontainer .cats .cats-inner {
  height: 100%;
  position: relative;
}

.cats .cats-inner .cats-inner-top {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  background: #f7f7f7;
}

.cats .cats-inner .cats-inner-bottom {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  -ms-flex-item-align: end;
  align-self: flex-end;
  background: #f7f7f7;
  border-left: 1px solid #e6e6e6;
}

.chatcontainer .right .write input {
  background: #fff;
  height: 46px;
  padding: 0 18px;
}

.chatcontainer .right .write input[type="checkbox"] {
  background: #fff;
  height: auto;
  padding: 0;
  display: inline-block;
}

.btn-primary .ll-send {
  font-size: 21px;
}

.chatcontainer .right .bubble .chatsend-time {
  color: #96ACBF;
  padding-left: 10px;
  font-size: 12px;
  float: right;
  margin-top: 1px;
}

.chatcontainer .right .bubble.me .chatsend-time {
  color: rgba(213, 224, 234, 1);
}

.chatcontainer .right .bubble .chat-like {
  color: #cccccc;
  position: absolute;
  right: -30px;
  top: 50%;
  margin-top: -7px;
  font-size: 20px;
}

.chatcontainer .right .bubble.me .chat-like {
  left: -30px;
  right: auto;
}

.chatcontainer .right .bubble .chat-like.active {
  color: #d0021b;
}

.fa-spinner {
  color: gray
}

.select-messages {
  margin-top: 20px;
  margin-right: 28%;
}

.select-messages li > div {
  cursor: pointer;
  max-width: 230px;
}

.tnc_link {
  cursor: pointer;
  text-decoration: underline;
  display: inline-block;
  padding: 10px;
}

.left-top-menu .Select-menu-outer {
  min-width: 220px;
}

.tov-wrapper .bubble-wrapper .bubble:not(:last-of-type):before, .tov-wrapper .bubble-wrapper .bubble:not(:last-of-type):after {
  display: block;
}

.tov-wrapper .bubble-wrapper .bubble:before, .tov-wrapper .bubble-wrapper .bubble:after {
  display: block;
}

.tov-wrapper .bubble-wrapper.me:not(.fixed-msg) .bubble.me {
  background: #1088E2;
}

.tov-wrapper .bubble-wrapper.me:not(.fixed-msg) .bubble.me:after {
  border-color: transparent #1088E2;
}

.tov-wrapper form {
  max-width: 340px;
  width: 340px;
  display: block;
  float: right;
}

.tov-wrapper .conversation-start span:before, .tov-wrapper .conversation-start span:after {
  width: 40%;
}

.tov-wrapper {
  height: calc(100vh - 44px);
  overflow: auto;
  margin: 0 -15px -10px;
  padding: 15px;
}

.bubble-wrapper.me.editable-msg {
  max-width: 90%;
  padding: 0;
}

.tov-fields {
  padding: 10px 10px 0;
  border: 1px dotted #DEE7EB;
  border-radius: 4px;
}

.tov-edit-top-btn {
  margin-top: -15px;
  margin-bottom: 5px;
  float: right;
}

.chatcontainer.candidate .left:not(.carousel-control):not(.item) {
  width: 507px;
  height: 100vh;
  background: #fff;
}

.candidate .message-container {
  max-width: calc(100vw - 507px);
}

.chatcontainer.candidate .right .chat {
  padding: 0 12vw 20px;
}

.profile-section-wrapper {
  padding: 0 10vw;
}

.chatcontainer .right .write.candidate {
  margin: 0 5%;
}

.write.candidate .bubble.me {
  float: none;
  margin-right: 20px;
  min-width: 70px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.write.candidate .bubble.me:before {
  background-color: #fff;
}

.conv-wrapper.candidate {
  height: -moz-calc(100vh - 116px);
  height: -webkit-calc(100vh - 116px);
  height: calc(100vh - 116px);
}

.candidate .left-center {
  overflow: auto;
  height: calc(100vh - 200px);
  height: -moz-calc(100vh - 200px);
  height: -webkit-calc(100vh - 200px);
}

.chatcontainer.candidate .left.mobile-left:not(.carousel-control):not(.item) {
  border: 0 none;
  width: 100vw;
  position: fixed;
  top: 0;
  bottom: 0;
  overflow: auto;
  z-index: 1005;
}

.chatcontainer.candidate .left.mobile-left:not(.carousel-control):not(.item) .left-center {
  max-height: calc(100% - 50px);
  height: auto;
  overflow: auto;
  width: 100%;
}

.chatcontainer.candidate .left.mobile-left:not(.carousel-control):not(.item) .form-login-container {
  padding: 30px;
}

.chatcontainer.candidate .left.mobile-left:not(.carousel-control):not(.item) .divider {
  margin: 0 -30px;
}

.chatcontainer.candidate .left.mobile-left:not(.carousel-control):not(.item) .left-top {
  min-height: 44%;
  border-bottom: 1px solid #DEE7EB;
  padding: 25px 10vw;
}

.chatcontainer.candidate .left.mobile-left:not(.carousel-control):not(.item) .left-center,
.chatcontainer.candidate .left.mobile-left:not(.carousel-control):not(.item) .candidate-footer-menu {
  min-height: 28%;
}

.chatcontainer.candidate .left.mobile-left:not(.carousel-control):not(.item) .left-center .candidate-info-menu {
  padding: 25px 5vw;
  float: left;
  width: 100%;
}

.chatcontainer.candidate .left.mobile-left:not(.carousel-control):not(.item) .left-center .candidate-info-menu .col-xs-6 {
  margin: 5px 0;
  color: #5E6F72;
  cursor: pointer;
}

.chatcontainer.candidate .left.mobile-left:not(.carousel-control):not(.item) .left-top .nav.blue-nav {
  background: #fff;
  text-align: left
}

.chatcontainer.candidate .left.mobile-left:not(.carousel-control):not(.item) .left-top .nav.blue-nav > li > a {
  margin-bottom: 0;
  padding: 10px 15px;
  text-align: left;
  color: #5E6F72;
  display: inline-block;
}

.chatcontainer.candidate .left.mobile-left:not(.carousel-control):not(.item) .left-top .nav.blue-nav > li > a > span {
  display: inline-block;
  margin: 0 0 0 15px;
  text-transform: none;
  vertical-align: top;
  font-size: 18px;
}

.chatcontainer.candidate .left.mobile-left:not(.carousel-control):not(.item) .left-top .nav.blue-nav > li > a > i {
  color: rgba(32, 75, 111, 0.5);
  position: static;
}

.chatcontainer.candidate .left.mobile-left:not(.carousel-control):not(.item) .left-top .nav.blue-nav > li > a.active-mobile > i,
.chatcontainer.candidate .left.mobile-left:not(.carousel-control):not(.item) .left-top .nav.blue-nav > li > a.active-mobile {
  color: #85B853;
}

.chatcontainer.candidate .left.mobile-left:not(.carousel-control):not(.item) .left-top .nav.blue-nav > li > a > i > span.badge {
  background: #29638f none repeat scroll 0 0;
  border: 0 none;
  color: #fff;
  height: 22px;
  top: 11px;
  font-size: 11px;
  width: 22px;
}

.candidate-footer-menu {
  background: #204B6F;
  color: #fff;
}

.candidate-footer-menu h3 {
  color: #fff;
  font-size: 16px;
}

.candidate-footer-menu p {
  opacity: 0.5;
}

.candidate-footer-menu .media {
  padding: 25px 5vw;
}


.left-top .nav.blue-nav {
  background: #204B6F;
}

.left-top .nav.blue-nav > li > a {
  padding: 25px 5px 0;
  color: rgba(255, 255, 255, 0.5);
  min-height: 110px;
}

.left-top .nav.blue-nav > li > a:hover {
  background: none;
  color: rgba(255, 255, 255, 0.75);
}

.left-top .nav.blue-nav > li.active > a, .left-top .nav.blue-nav > li.active > a:hover {
  color: #fff;
}

.left-top .nav.blue-nav > li > a > span {
  display: block;
  text-transform: uppercase;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 14px;
  margin-top: 10px;
}

.left-top .nav.blue-nav > li > a > i {
  position: relative;
  font-size: 21px;
}

.left-top .nav.blue-nav > li > a > i > span.badge {
  background: #fff none repeat scroll 0 0;
  border: 4px solid #054b72;
  border-radius: 50%;
  color: #4a7aa9;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 13px;
  font-weight: 700;
  height: 30px;
  padding: 5px 0;
  position: absolute;
  right: -20px;
  top: -15px;
  width: 30px;
}

.btn.choose-topic {
  left: 175px;
  font-size: 14px;
  padding: 3px 15px;
  height: auto;
  position: absolute;
  font-weight: normal;
  z-index: 100;
  color: #29638f;
}

.mobile-header {
  background: #204b6f;
  color: #fff;
  float: left;
  width: 100%;
}

.title-mobile {
  display: block;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  padding: 13px 0;
  text-transform: uppercase;
}

.job-item, .profile-menu-item, .pipeline-group {
  padding: 15px;
  cursor: pointer;
  margin: 20px !important;
  border-radius: 4px;
  cursor: pointer;
}

.profile-menu-item.media {
  vertical-align: top;
}

.profile-menu-item.media .media-left i {
  font-size: 20px;
  color: #29638F;
}

.select-job .job-item {
  border: 1px solid #DEE7EB;
}

.select-job .job-item:hover {
  border: 1px solid #1088E2;
}

.job-item:hover, .profile-menu-item:hover {
  background: #f8fafb;
}

.job-item h3, .profile-menu-item h3, .profile-section-wrapper h3 {
  margin: 0;
  font-size: 16px;
  color: #29638F;
}

.job-item h4 {
  margin: 15px 0;
}

.job-company {
  color: #546877;
}

.job-pipeline .company-img-crop {
  width: 50px;
  height: 50px;
}

.profile-section-wrapper h3 {
  margin: 20px 0;
}

.profile-section-wrapper h4 {
  color: #1088E2;
  border-bottom: 1px dotted #1088E2;
  padding-bottom: 10px;
}

.section hr {
  margin: 10px 0;
}

.profile-section-wrapper .edit-user {
  margin-bottom: 30px;
}

.profile-menu-item h3 {
  margin-bottom: 15px;
}

.pipeline-group {
  cursor: default;
}

.pipeline-group h4 {
  color: #fff;
  margin: 0;
}

.job-item.active h3, .job-item.active:hover h3, .profile-menu-item.active h3, .profile-menu-item.active:hover h3,
.profile-menu-item.active .media-left i, .profile-menu-item.active:hover .media-left i {
  color: #1088E2;
}

.job-item.active, .job-item.active:hover, .profile-menu-item.active, .profile-menu-item.active:hover {
  background: #f2f6f9;
}

.job-pipelines, .job-pipelines i, .job-pipelines span {
  display: inline-block;
  vertical-align: top;
}

.job-pipelines {
  margin-right: 25px;
  margin-top: 5px;
}

.job-pipelines .ll-icon {
  color: #7D8D91;
  opacity: 0.4;
  font-size: 18px;
  margin-right: 5px;
}

.job-central-panel {
  height: -moz-calc(100vh - 48px);
  height: -webkit-calc(100vh - 48px);
  height: calc(100vh - 48px);
  overflow: auto;
  z-index: 1004;
  background: #fff;
}

.job-central-panel .logo-container {
  display: none;
}

.job-central-panel .job-info-block.sticky-top {
  left: 507px;
  right: 0;
  width: auto;
  top: 47px;
}

.progress-line {
  margin-top: 10px;
  margin-right: 5px;
}

.progress-line > div {
  border-bottom: 2px solid rgba(188, 198, 200, 0.3);
}

.progress-line > div.active, .progress-line > div.active.ended {
  border-bottom: 2px solid #4A7AA9;
  opacity: 1;
}

.progress-line > div.active {
  z-index: 1;
}

.progress-line > div.active.my-jobs {
  border-bottom: 2px solid #85B852;
}

.progress-line > div .fa {
  font-size: 10px;
  position: absolute;
  top: -4.5px;
}

.progress-line > div.active .fa {
  color: #4A7AA9;
}

.progress-line > div.active.my-jobs .fa {
  color: #85B852 !important;
}

.progress-line > div .fa:first-child {
  left: -4px;
}

.progress-line > div .fa:last-child {
  right: -4px;
}

.progress-line > div.ended {
  border-bottom: 2px dashed #BCC6C8;
}

.progress-line > div .ll-icon {
  color: #E2574C;
  left: calc(50% - 7px);
  font-size: 14px;
  position: absolute;
  top: -6.5px;
}

.job-item .label-default {
  white-space: normal;
}

.info-message {
  margin: 50px;
}

.profile-section-wrapper .panel-block {
  margin: 0;
  padding: 0;
}

.candidate .left-center .nav.nav-tabs {
  border-bottom: 1px solid #DEE7EB;
}

.candidate-details-header {
  padding: 20px;
  text-align: center;
}

.candidate-details-header .user-avatar-crop {
  width: 105px;
  height: 105px;
}

.profile-section-wrapper .candidate-details-header h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.candidate-details-header p {
  color: #546877;
}

.facebook-icon, .linkedin-icon, .twitter-icon, .skype-icon {
  background: #4065B4;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
}

.linkedin-icon {
  background: #0076B7;
}

.twitter-icon {
  background: #000;
}

.skype-icon {
  background: #0078ca;
}

.icon.icon-gray {
  background-color: #96acbf;
}

.education-item, .wh-item, .language-item {
  padding-top: 10px;
  border-top: 1px dotted #dee7eb;
}

.loader.chat-loader {
  width: calc(100vw - 507px);
  right: 0;
}

.job-list.availability-edit-list {
  overflow: auto;
}

h3.job-role {
  font-size: 16px;
  margin: 0 0 5px 0;
}

/* home page carousel */
.home-page-carousel {
  border-radius: 4px;
  overflow: hidden;
  background: #96acbf;
}

.home-page-carousel .carousel-caption {
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(84, 104, 119, 0.75);
}

.home-page-carousel .carousel-caption h4 {
  font-weight: 500;
  color: #fff;
  margin-top:0;
  padding: 0 10px;
}

.home-page-carousel .carousel-caption p {
  color: #fff;
  margin:0;
  padding: 0 10px;
}

.static-pages-wrapper ul > li {
  display: block;
  padding: 10px;
  border: 1px solid #F2F6F9;
  background: #F2F6F9;
  border-radius: 4px;
  color: #1088E2;
}

.static-pages-wrapper ul > li:hover {
  border: 1px solid #1088E2;
  text-decoration: none;
  cursor: pointer;
}

.static-pages-wrapper ul > li.active, .static-pages-wrapper ul > li.active:hover {
  border: 1px solid #1088E2;
  text-decoration: none;
  background: #E5EFF5;
}

/* Large desktops and laptops */
@media only screen and (min-width: 1025px) {
  .message-content .col-sm-4.col-xs-12.margin-min-vertical {
    padding: 0 5px;
    max-width: 30%;
  }
  .a-day {
    width: 13.5%;
  }
}

/* Landscape tablets and medium desktops */
@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .message-content .col-sm-4.col-xs-12.margin-min-vertical {
    padding: 0 5px;
    max-width: 30%;
  }

  .chatcontainer.candidate .left:not(.carousel-control):not(.item) {
    width: 40%;
  }

  .left-top .nav.blue-nav > li > a > span {
    font-size: 12px;
  }

  .candidate .message-container {
    max-width: calc(100vw - 40%);
  }

  .chatcontainer.candidate .right .chat {
    padding: 0 2vw 20px;
  }

  .candidate .profile-section-wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }

  .job-central-panel .job-info-block.sticky-top {
    left: 40%;
  }

  .loader.chat-loader {
    width: calc(100vw - 40%);
  }

  .job-content .company-img-crop {
    width: 60px;
    height: 60px;
  }
}

/* Portrait tablets and small desktops */
@media only screen and (min-width: 769px) and (max-width: 991px) {
  .chatcontainer.candidate .left:not(.carousel-control):not(.item) {
    width: 40%;
  }

  .candidate .message-container {
    max-width: calc(100vw - 40%);
  }

  .candidate .left-center .form-login-container {
    padding: 30px;
  }

  .chatcontainer.candidate .right .chat {
    padding: 0 30px 20px;
  }

  .candidate .profile-section-wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }

  .left-top .nav.blue-nav > li > a {
    padding: 25px 1px;
  }

  .left-top .nav.blue-nav > li > a > span {
    font-size: 12px;
  }

  .message-content .col-sm-4.col-xs-12.margin-min-vertical {
    padding: 0 5px;
    max-width: 30%;
  }

  .job-central-panel .job-info-block.sticky-top {
    left: 40%;
  }

  div.profile-section-wrapper::before {
    width: calc(100vw - 40%);
  }

  .loader.chat-loader {
    width: calc(100vw - 40%);
  }

  .job-content .company-img-crop {
    width: 60px;
    height: 60px;
  }
}

/* Landscape phones and portrait tablets */
@media only screen and (min-width: 576px) and (max-width: 768px) {
  .chatcontainer .right:not(.carousel-control):not(.item), .message-container {
    width: 100%;
  }

  .chatcontainer.candidate .left {
    display: block;
  }

  .chatcontainer.candidate {
    width: 100%;
  }

  .conv-wrapper.candidate {
    height: calc(100vh - 166px);
  }

  .job-central-panel {
    height: calc(100vh - 50px);
  }

  .job-central-panel .job-info-block.sticky-top {
    left: 0;
    right: 0;
    width: auto;
    top: 50px;
  }

  .chatcontainer.candidate .left:not(.carousel-control):not(.item) {
    height: 100%;
  }

  .message-content .col-sm-4.col-xs-12.margin-min-vertical {
    padding: 0 5px;
    max-width: 30%;
  }

  .candidate .message-container {
    max-width: 100vw;
  }

  .candidate-profile-menu {
    padding-bottom: 30px;
  }

  .job-central-panel .a-day {
    width: 13.5%;
  }

  .mobile-left .nav-justified > li {
    display: block;
    width: 100%;
    min-height: auto;
  }

  .loader.chat-loader {
    width: 100%;
  }

  .left-top .nav.blue-nav > li > a {
    min-height: auto;
  }

  .job-content .company-img-crop {
    width: 40px;
    height: 40px;
  }
}

/* Small phones */
@media only screen and (min-width: 381px) and (max-width: 575px) {
  .chatcontainer .right:not(.carousel-control):not(.item), .message-container {
    width: 100%;
  }

  .chatcontainer.candidate .left {
    display: block;
  }

  .chatcontainer.candidate {
    width: 100%;
  }

  .conv-wrapper.candidate {
    height: calc(100vh - 166px);
  }

  .chatcontainer.candidate .right .chat {
    padding: 0 5vw 20px;
  }

  .profile-section-wrapper {
    padding: 0 5vw;
  }

  .job-central-panel {
    height: calc(100vh - 50px);
  }

  .job-central-panel .job-info-block.sticky-top {
    left: 0;
    right: 0;
    width: auto;
    top: 50px;
  }

  .chatcontainer.candidate .left:not(.carousel-control):not(.item) {
    height: 100%;
  }

  .message-content .col-sm-4.col-xs-12.margin-min-vertical {
    padding: 0 5px;
    max-width: 30%;
  }

  .bubble-with-input {
    margin-bottom: 10px;
  }

  .bubble-with-input + div {
    width: 100%;
  }

  .candidate .message-container {
    max-width: 100vw;
  }

  .write.candidate .bubble.me {
    padding: 10px;
    outline: none;
  }

  .chatcontainer .right .write {
    padding: 15px 0;
  }

  .chatcontainer.candidate .left.mobile-left .left-top {
    border-bottom: 0;
  }

  .job-item, .profile-menu-item, .pipeline-group {
    margin: 10px !important;
  }

  .collapse-btn {
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 10px;
    float: left !important;
  }

  .candidate-profile-menu {
    padding-bottom: 30px;
  }

  .job-central-panel .a-day {
    width: 13.5%;
  }

  .loader.chat-loader {
    width: 100%;
  }

  .mobile-left .nav-justified > li {
    min-height: auto;
  }

  .left-top .nav.blue-nav > li > a {
    min-height: auto;
  }

  .job-content .company-img-crop {
    width: 40px;
    height: 40px;
  }

  .message-content iframe, .message-content video {
    max-width: 100%;
  }
}

@media only screen and (min-width: 341px) and (max-width: 380px) {
  .chatcontainer .right:not(.carousel-control):not(.item), .message-container {
    width: 100%;
  }

  .chatcontainer.candidate .left {
    display: block;
  }

  .chatcontainer.candidate {
    width: 100%;
  }

  .conv-wrapper.candidate {
    height: calc(100vh - 166px);
  }

  .chatcontainer.candidate .right .chat {
    padding: 0 5vw 20px;
  }

  .profile-section-wrapper {
    padding: 0 5vw;
  }

  .job-central-panel {
    height: calc(100vh - 50px);
  }

  .job-central-panel .job-info-block.sticky-top {
    left: 0;
    right: 0;
    width: auto;
    top: 50px;
  }

  .chatcontainer.candidate .left:not(.carousel-control):not(.item) {
    height: 100%;
  }

  .message-content .col-sm-4.col-xs-12.margin-min-vertical {
    padding: 0 5px;
    max-width: 30%;
  }

  .tnc_link {
    padding: 0 24px 10px 0;
    width: 100%;
  }

  .tnc_link + .bubble, .tnc_link + .bubble + .bubble {
    margin-bottom: 0;
  }

  .bubble-with-input {
    margin-bottom: 10px;
  }

  .bubble-with-input + div {
    width: 100%;
  }

  .candidate .message-container {
    max-width: 100vw;
  }

  .write.candidate .bubble.me {
    padding: 10px;
    outline: none;
  }

  .chatcontainer .right .write {
    padding: 15px 0;
  }

  .chatcontainer.candidate .left.mobile-left:not(.carousel-control):not(.item) .left-top {
    border-bottom: 0;
  }

  .job-item, .profile-menu-item, .pipeline-group {
    margin: 10px !important;
  }

  .collapse-btn {
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 10px;
    float: left !important;
  }

  .candidate-profile-menu {
    padding-bottom: 30px;
  }

  .job-central-panel .a-day {
    width: 13.5%;
  }

  .loader.chat-loader {
    width: 100%;
  }

  .mobile-left .nav-justified > li {
    min-height: auto;
  }

  .left-top .nav.blue-nav > li > a {
    min-height: auto;
  }

  .job-content .company-img-crop {
    width: 40px;
    height: 40px;
  }

  .message-content iframe, .message-content video {
    max-width: 100%;
  }
}

@media (max-width: 340px) {
  .chatcontainer .right:not(.carousel-control):not(.item), .message-container {
    width: 100%;
  }

  .chatcontainer.candidate .left {
    display: block;
  }

  .write.candidate .bubble.me {
    margin-bottom: 10px;
  }

  .write.candidate .bubble.me:last-child {
    margin-bottom: 0;
  }

  .chatcontainer.candidate {
    width: 100%;
  }

  .conv-wrapper.candidate {
    height: calc(100vh - 166px);
  }

  .chatcontainer.candidate .right .chat {
    padding: 0 5vw 20px;
  }

  .profile-section-wrapper {
    padding: 0 5vw;
  }

  .job-central-panel {
    height: calc(100vh - 50px);
  }

  .job-central-panel .job-info-block.sticky-top {
    left: 0;
    right: 0;
    width: auto;
    top: 50px;
  }

  .chatcontainer .right .write .input-group textarea.form-control {
    font-size: 13px;
  }

  .job-item .label {
    padding: 10px 8px;
    font-size: 11px;
  }

  .chatcontainer.candidate .left:not(.carousel-control):not(.item) {
    height: 100%;
  }

  .message-content .col-sm-4.col-xs-12.margin-min-vertical {
    padding: 0 5px;
    max-width: 30%;
  }

  .tnc_link {
    padding: 0 24px 10px 0;
    width: 100%;
  }

  .write.candidate .tnc_link + .bubble, .write.candidate .tnc_link + .bubble + .bubble {
    margin-bottom: 0;
  }

  .btn.choose-topic {
    left: 175px;
    font-size: 14px;
    padding: 3px 15px;
    height: auto;
  }

  .chatcontainer .right .write {
    min-height: 47px;
  }

  .bubble-with-input {
    margin-bottom: 10px;
  }

  .bubble-with-input + div {
    width: 100%;
  }

  .candidate .message-container {
    max-width: 100vw;
  }

  .write.candidate .bubble.me {
    padding: 10px;
    outline: none;
  }

  .chatcontainer .right .write {
    padding: 15px 0;
  }

  .chatcontainer.candidate .left.mobile-left:not(.carousel-control):not(.item) .left-top {
    border-bottom: 0;
  }

  .job-item, .profile-menu-item, .pipeline-group {
    margin: 10px !important;
  }

  .collapse-btn {
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 10px;
    float: left !important;
  }

  .candidate-profile-menu {
    padding-bottom: 30px;
  }

  .job-central-panel .a-day {
    width: 13.5%;
  }

  .photo-carousel .photo-carousel-close {
    right: 20px;
  }

  .loader.chat-loader {
    width: 100%;
  }

  .mobile-left .nav-justified > li {
    min-height: auto;
  }

  .left-top .nav.blue-nav > li > a {
    min-height: auto;
  }

  .job-content .company-img-crop {
    width: 40px;
    height: 40px;
  }

  .message-content iframe, .message-content video {
    max-width: 100%;
  }
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
