.job-detail-container {
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 20px;
}

.job-detail-container.logo-container .signin-logo {
  margin: 20px auto;
  height: 65px;
  display: block;
}

.job-detail-container h3.media-heading {
  font-size: 24px;
  margin-bottom: 0;
  color: #fff;
}

.job-detail-container h3 {
  margin-top: 0;
  font-size: 24px;
}

.job-detail-container .media-body h4 {
  margin-top: 16px;
  margin-bottom: 0;
  color: #fff;
}

.job-detail-container .media-body h4 .text-muted, .job-detail-container .salary-currency {
  opacity: 0.6;
}

.job-detail-container .media.title-block .company-img-crop {
  margin: 0;
  border-width: 1px;
  width: 60px;
  height: 60px;
}

.job-detail-container .media-object {
  width: 100%;
  height: auto;
}

.job-detail-container .table {
  margin: 0;
}

.apply {
  text-align: center;
  margin-bottom: 10px;
}

.apply .btn {
  padding-left: 15px;
  padding-right: 15px;
}

.bottom-btn .reject {
  display: none;
}

.bottom-btn .apply {
  text-align: center;
}

.reject {
  text-align: center;
  display: inline-block;
}

.tell-more {
  display: inline-block;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.6);
  margin-right: 15px;
}

.reject .btn, .tell-more .btn {
  padding: 0;
}

.reject .btn .ll-icon, .tell-more .btn .ll-icon {
  font-size: 18px;
  vertical-align: text-top;
}

.job-detail-container .ll-icon, .job-detail-container .fa {
  color: #96ACBF;
}

.job-image-container {
  position: relative;
  margin: 0 0 20px 0;
  overflow: hidden;
}

.job-image {
  height: 100%;
}

.photo-carousel {
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(13, 31, 48, 0.8);
  z-index: 999;
}

.photo-carousel .photo-carousel-close {
  color: white;
  position: absolute;
  right: 50px;
  top: 20px;
  z-index: 10;
  font-size: 32px;
}

.photo-carousel .carousel.slide {
  height: 70vh;
  margin: 15vh auto;
}

.carousel-inner > .active {
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  height: 70vh;
  position: relative;
}

.carousel-indicators {
  bottom: -40px;
}

.carousel-inner > .item > img, .carousel-inner > .item > a > img {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.carousel-control.left, .carousel-control.right {
  background: none;
}

.job-info-block {
  margin-bottom: 20px;
}

.job-cover-color {
  padding: 20px 0;
  background: #204b6f;
}

.bottom-btn-block {
  background: rgba(32, 75, 111, 1);
}

.about-container > div div {
  margin-bottom: 10px;
  line-height: 1.5em;
}

.about-container > div div:last-child {
  margin-bottom: 0;
}

.list-format ul li {
  list-style: disc;
  margin-left: 18px;
}

.list-format ol li {
  list-style: decimal;
  margin-left: 18px;
}

.map > div {
  width: 100%;
  height: 320px !important;
}

.sub-text {
  margin-top: 15px;
  color: #4a4a4a;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
}

.flag-wrapper {
  width: 25px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  margin-right: 10px;
  vertical-align: middle;
  height: 16px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .3);
}

.flag-wrapper .flag-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.job-info-block.sticky-top {
  position: fixed;
  background: #fff;
  z-index: 900;
  top: 0;
  border-bottom: 1px solid #DEE7EB;
  width: 100%;
  left: 0;
}

.clear-block {
  padding-bottom: 150px;
}

.job-detail-action.second-line {
  margin-top: 5px;
  text-align: center;
}

.view-photo {
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.btn-photo .btn-link {
  color: #58A2D7;
}

.photo-slider > div {
  display: inline-block;
  height: 320px;
  border: 1px solid #fff;
}

.photo-slider > div > img {
  height: 320px;
}

.photo-slider.text-center > div > img {
  height: 320px;
  width: auto;
}

.photo-slider.text-center > div {
  height: 320px;
  overflow: hidden;
}

.photo-slider {
  overflow-y: auto;
  white-space: nowrap;
  padding-bottom: 20px;
  margin-bottom: -30px;
  text-align: center;
}

#job-disclaimer {
  color: #fff;
  background: #546877;
  margin-bottom: 0;
  padding: 20px 0;
}

#job-location-address + .bottom-btn-block + #job-disclaimer {
  margin-top: -20px;
}

#job-working-hours .col-sm-8 .collapse-panel > div:last-child,
#job-working-hours .col-sm-4 .collapse-panel > div:first-child,
#job-working-hours .col-sm-4 .collapse-panel .clearfix + .margin-top > div:first-child,
#job-working-hours .col-sm-4 .collapse-panel .clearfix + .margin-top > div:last-child > .col-xs-4 {
  display: none;
}

#job-working-hours .col-sm-4 .collapse-panel .clearfix + .margin-top,
#job-working-hours .col-sm-4 .collapse-panel .clearfix + .margin-top > .row.margin-bottom {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

#job-working-hours .col-sm-4 .collapse-panel .clearfix + .margin-top > div:last-child > .col-xs-8 {
  width: 100% !important;
}

#job-working-hours .a-month {
  margin-top: 0;
}

/** availability **/
.availability-block {
  display: flex;
}

.availability-block > div {
  flex-direction: row;
}

.a-item {
  border-radius: 1px;
  overflow: hidden;
  margin: 6px 4px;
  height: 10px;
  text-align: left;
}

.a-item > span {
  display: inline-block;
  border-right: 1px solid #fff;
}

.a-item > span:last-child {
  border-right: 0 solid #fff;
}

.a-day .tooltip-wrapper-box {
  display: block;
}

.a-day {
  float: left;
  width: 13.8%;
  margin: 1px;
  text-align: center;
  line-height: 1.2em;
  font-size: 12px;
  min-height: 37px;
}

div > .a-day:nth-child(6), div > .a-day:nth-child(7) {
  background: rgba(248, 250, 251, 1);
}

.a-day > small {
  background: #b4d5ed;
  color: #fff;
  min-height: 0;
  display: block;
  padding: 3px 5px;
  font-size: 10px !important;
}

.a-day.a-date > small {
  background: none;
  opacity: 0.85;
  min-height: 0;
  display: block;
  padding: 3px 3px 0;
  font-size: 10px !important;
  color: #9fb2c4;
}

.a-day.a-date.current-month > small {
  opacity: 1;
  color: #4a4a4a;
}

.a-month {
  margin: 0 0 5px 0;
  color: #546877;
}

.a-day.a-date.current-date.current-month > small {
  color: #29638F;
}

.a-day.a-date.current-date.current-month {
  background: #f2f6f9 !important;
}

.legend-box p {
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.job-list.availability-edit-list {
  background: rgb(247, 248, 249);
  height: calc(100vh - 405px);
  border: 1px solid rgb(220, 220, 220);
  border-radius: 4px;
}

.section .a-month {
  margin: -10px 0 10px 0;
}

.with-shadow {
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
}

.with-shadow .no-background, .no-background, .no-background:hover, .users li.no-background:hover {
  background: none !important;
}

.legend-item {
  display: inline-block;
  vertical-align: middle;
  height: 10px;
  width: 20px;
}

.question-text {
  border-left: 3px solid #96ACBF;
  padding: 0px 0 4px 7px;
  color: #546877;
}

.question .form-group {
  margin-bottom: 30px;
}

.requirements-and-skills-section {
  display: flex;
  flex-wrap: wrap;
}
.padding-top-wrapper {
  padding-top: 50px;
}

/* Portrait tablets and small desktops */
@media only screen and (min-width: 769px) and (max-width: 991px) {
  .job-detail-container .media-object {
    width: 60px;
    height: 60px;
  }
}

/* Landscape phones and portrait tablets */
@media only screen and (min-width: 576px) and (max-width: 768px) {
  .chatcontainer.candidate, .messenger-container, .center-right-content {
    display: block !important;
    width: 100%;
    float: left;
  }

  .job-detail-container.logo-container .signin-logo {
    height: 40px;
  }

  .media.title-block .media-left {
    display: block;
    text-align: center;
    padding: 0;
    margin-bottom: 10px;
  }

  .job-detail-container .media.title-block .company-img-crop {
    width: 45px;
    height: 45px;
    margin: 0 auto;
  }

  .media.title-block .media-body {
    text-align: center;
  }

  .apply-block {
    margin: 20px auto 10px;
    width: 40%;
    min-width: max-content;
  }

  .job-detail-container h3, .job-detail-container h3.media-heading {
    font-size: 18px;
  }

  .job-detail-container .job-info-block h4, .job-detail-container .media-body h4 {
    font-size: 14px;
  }

  .job-detail-container .media-body h4 {
    margin-bottom: 15px;
  }

  .job-info-block.sticky-top {
    position: relative;
  }

  .job-info-block#job-description {
    padding-top: 0!important;
  }

  .bottom-btn-block {
    margin-top: -20px;
    float: left;
    width: 100%;
  }

  .bottom-btn-block .job-detail-action.second-line {
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .bottom-btn-block + #job-disclaimer {
    margin-top: -20px;
  }
}

/* Small phones */
@media only screen and (max-width: 575px) {
  .chatcontainer.candidate, .messenger-container, .center-right-content {
    display: block !important;
    width: 100%;
    float: left;
  }

  .job-detail-container.logo-container .signin-logo {
    height: 40px;
  }

  .media.title-block .media-left {
    display: block;
    text-align: center;
    padding: 0;
    margin-bottom: 10px;
  }

  .job-detail-container .media.title-block .company-img-crop {
    width: 45px;
    height: 45px;
    margin: 0 auto;
  }

  .media.title-block .media-body {
    text-align: center;
  }

  .apply-block {
    margin: 20px auto 10px;
    width: auto;
    display: inline-block;
    vertical-align: middle;
  }

  .job-detail-container h3, .job-detail-container h3.media-heading {
    font-size: 18px;
  }

  .job-detail-container .job-info-block h4, .job-detail-container .media-body h4 {
    font-size: 14px;
  }

  .job-detail-container .media-body h4 {
    margin-bottom: 15px;
  }

  .job-info-block.sticky-top {
    position: relative;
  }

  .job-info-block#job-description {
    padding-top: 0!important;
  }

  .bottom-btn-block {
    margin-top: -20px;
    float: left;
    width: 100%;
  }

  .bottom-btn-block .job-detail-action.second-line {
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .bottom-btn-block + #job-disclaimer {
    margin-top: -20px;
  }
}

@media only screen and (max-width: 475px) {
  .a-day {
    width: 100%;
    margin: 0 0 5px 0;
  }
}
