.externalApplicationButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  & .icon {
    color: white;
  }
}
