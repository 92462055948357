#WeekSchedulerTable table {
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  table-layout: fixed;
  margin: 0;
}

.scroll-table-body {
  overflow: auto;
  height: calc(100vh - 206px);
}

#WeekSchedulerTable table thead tr th {
  background: #f8fafb none repeat scroll 0 0;
  border-bottom: 2px solid #DEE7EB;
  border-top: 1px solid #DEE7EB;
  overflow: hidden;
  padding: 5px 5px 7px;
}

#WeekSchedulerTable table thead tr th .day {
  display: block;
  width: 30px;
  height: 30px;
  margin: 0 auto 5px;
  padding: 5px;
  line-height: 1.8;
}

#WeekSchedulerTable table thead tr th .day.active {
  background: #d1d5db;
  color: #626e7e;
}

#WeekSchedulerTable table thead tr th .short, #WeekSchedulerTable table thead tr th .long {
  margin-bottom: 5px;
}

#WeekSchedulerTable table thead tr th .short {
  display: none;
}

#WeekSchedulerTable table thead tr th i {
  vertical-align: middle;
  font-size: 2em;
}

#WeekSchedulerTable table tbody tr:nth-child(2n+0) td {
  border-bottom: 1px solid #fafafa;
}

#WeekSchedulerTable table tbody tr td {
  text-align: center;
  border-left: 1px solid #fafafa;
  position: relative;
  height: 12px;
  cursor: pointer;
}

#WeekSchedulerTable table tbody tr td:last-child {
  border-right: 0 solid #626e7e;
}

#WeekSchedulerTable table tbody tr td.hour {
  padding: 0;
  background: #e8edf0 none repeat scroll 0 0;
  border-bottom: 1px solid #fafafa;
  border-left: 0;
  border-collapse: separate;
  min-width: 50px;
  cursor: default;
  font-size: 12px;
}

#WeekSchedulerTable table tbody tr td.hour span {
  display: block;
}

#WeekSchedulerTable {
  width: 100%;
  line-height: normal;
}

#EventSelector {
  padding: 10px;
}

#EventSelector div.legend-body {
  vertical-align: middle;
}

#EventSelector div .radio-item {
  display: inline-block;
  margin-right: 20px;
}

#EventSelector div .radio-item .badge {
  cursor: pointer;
}

#EventSelector div .radio-item .badge > input {
  margin-top: 0;
  margin-right: 5px;
  margin-left: -2px;
}

.radio-item .badge {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
}

.legend-body .radio-item:first-child .badge {
  color: #4a4a4a;
}

.time-cell {
  position: absolute;
  padding: 3px;
  z-index: 1;
  text-shadow: 0.5px 0.5px 1px #7A8E92;
}
