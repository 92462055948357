.message-content img {
  padding: 10px;
  width: 100%;
}

.message-content .spinning-logo {
  padding: 0;
}

.message-content {
  display: block;
}

.me .job-content {
  background: #2d72a3;
  margin-top: 10px !important;
}

.you .job-content {
  background: #e1eaef;
  margin-top: 10px !important;
}

.job-content {
  border-radius: 3px;
  padding: 10px;
  position: relative;
  min-width: 170px;
}

.bubble .job-content .media-body, .bubble .job-content .media-left {
  vertical-align: top;
}

.job-content .company-img-crop {
  width: 100px;
  height: 100px;
}

.job-content .job-skill-item::after {
  content: ',';
  display: inline-block;
  margin-right: 5px;
}

.job-content p.small  {
  line-height: 1.5em;
}

.job-content p.small .job-skill-item:last-child:after {
  display: none;
}

.inline-msg .media-left .company-img-crop {
  margin: 1px 1px 2px;
}

.message-content .job-content .company-img-crop img {
  padding: 0;
}

.me .job-content .company {
  color: rgba(255, 255, 255, 0.65);
}

.me .job-content .title {
  color: #fff;
}

.you .job-content .title {
  color: #29638F;
}

.job-content .title {
  font-size: 16px;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  margin-right: 0;
}

.me .job-content .details {
  color: rgba(255, 255, 255, 0.65) !important;
}

.job-content .details {
  font-size: 16px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.bubble.inline-msg {
  width: 100%;
  max-width: 100%;
  margin: 0;
  border-radius: 4px;
  padding: 7px 10px;
  background: #fff;
  border: 1px dotted #DEE7EB;
}

.bubble .media-body, .bubble .media-left, .bubble .media-right {
  vertical-align: middle;
  overflow: visible;
}

.chatcontainer .right .chat.active-chat .text-right > small {
  color: #96ACBF;
}

.bubble.inline-msg.notes {
  padding: 0;
  background: none;
}

.bubble.inline-msg.notes:before, .bubble.inline-msg.notes:after {
  bottom: 10px;
  height: 10px;
}

.bubble.inline-msg.notes .bubble-content {
  background: #F7FAFC;
  border: 1px solid #CADFEF;
  border-left-width: 3px;
}

.bubble.inline-msg.notes .bubble-content:before {
  border-color: #CADFEF #fff #fff #CADFEF;
  background: #CADFEF;
}

.modal-body .control-label.col-xs-2 {
  padding-top: 6px;
}

.icon-circle {
  width: 45px;
  height: 45px;
  margin: 0 auto;
  text-align: center;
  border: 1px solid;
  border-radius: 50%;
}

.icon-circle .fa-2x {
  font-size: 20px !important;
  line-height: 43px;
}

.inline-msg h3 {
  margin: 0;
  font-size: 14px;
  white-space: nowrap;
  line-height: 1.5em;
}

.bubble.inline-msg .media-body, .bubble.inline-msg .media-left, .bubble.inline-msg .media-right {
  vertical-align: top;
}

.bubble.inline-msg.notes .media, .bubble.inline-msg.notes .media-body, .bubble.inline-msg.notes .media-left, .bubble.inline-msg.notes .media-right {
  vertical-align: middle;
  overflow: visible;
}

.bubble.inline-msg .fa-stack-1x.fa-arrow-up, .bubble.inline-msg .fa-stack-1x.fa-arrow-down {
  font-size: 10px;
  width: 12px;
  height: 12px;
  background: #fff;
  display: inline-block;
  line-height: 1em;
  border-radius: 50%;
  left: auto;
  top: 22px;
  right: 3px;
}

.bubble.inline-msg.notes {
  border: 0;
  border-radius: 0;
}

.bubble.inline-msg.notes h3, .pipeline-msg.inline-msg h3 {
  white-space: normal;
}

.bubble-wrapper.me:not(.loading-msg), .bubble-wrapper.you, .chatcontainer .right .conversation-start {
  -webkit-animation: fadein 0.5s linear normal;
  animation: fadein 0.5s linear normal;
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.me .outer-loading {
  position: absolute;
  left: -25px;
  top: 10px;
}

.you .outer-loading {
  position: absolute;
  right: -25px;
  top: 10px;
}

.activity-compact {
  font-size: 12px;
  padding: 10px 0 25px 25px;
  position: relative;
  clear: both;
}

.activity-compact.hide-activity {
  padding: 5px 0 0 25px;
}

.activity-compact.hide-activity .activity-icon {
  top: 7px;
}

.hide-activity {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.agent-messenger:hover + .agent-messenger .hide-activity,.agent-messenger:hover .hide-activity, .activity-compact:hover {
  visibility: visible;
  opacity: 1;
}

.activity-compact .btn-tool-box.no-padding, .notes .bubble-content .message-content > div {
  font-size: 12px;
}

.activity-compact .activity-icon {
  position: absolute;
  left: 0;
  top: 12px;
}
